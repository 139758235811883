import Vue from 'vue'
import moment from 'moment'
import filesize from 'filesize'
import pluralize from 'pluralize'
import {titleCase} from 'change-case'
import Lang from './Lang'

Vue.filter('trans', (...args) => {
    return Lang.get(...args)
})

Vue.filter('human_fulldatetime', (value) => {
    return moment.parseZone(value).local().format('Do MMMM YYYY @ HH:mm A')
})

Vue.filter('days', (value) => {
    return moment.utc(value).local().diff(moment.utc().local(), 'days')
})

Vue.filter('date', (value) => {
    return moment.utc(value).local().format('Do MMMM YYYY')
})

Vue.prototype.formatDate = (value) => {
    return moment.utc(value).local().format('Do MMMM YYYY')
}

Vue.filter('date_short', (value) => {
    return moment.utc(value).local().format('DD/MM/YYYY')
})

Vue.filter('time', (value) => {
    return moment.utc(value).local().format('HH:mm')
})

Vue.filter('datetime', (value) => {
    return moment.utc(value).local().format('DD/MM/YYYY @ HH:mm')
})

Vue.filter('fulldatetime', (value) => {
    return moment.utc(value).local().format('DD/MM/YYYY @ HH:mm:ss')
})

Vue.filter('now', (value) => {
    return moment.utc(value).local().fromNow()
})

Vue.filter('calendar', (value) => {
    return moment.utc(value).local().calendar()
})

// format of 2 will default to MB
Vue.filter('filesize', (value, places = 2, format = 2, base = 10) => {
    return filesize(value,
      {
          round: places,
          exponent: format,
          base: base
      })
})

Vue.filter('titleCase', (value) => {
    return titleCase(value)
})

Vue.filter('pluralize', (value, word) => {
    return pluralize(word, value)
})

Vue.filter('truncate', (value, size = 20) => {
    return value.length > size ? value.substring(0, size) + '...' : value;
})

Vue.filter('currency', value => {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
    }).format(value);
})

Vue.prototype.formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
    }).format(value);
}

