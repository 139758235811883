
import Lang from 'lang.js'
import translations from './lang/messages.json'

const lang = new Lang({
  locale: 'en',
})
lang.setLocale('en')
lang.setMessages(translations)

export default lang