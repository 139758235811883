import axios from "axios";

const ExtractApiError = (e) => {
    const defaultMessage = 'Unknown error occurred'

    if (typeof e === 'string') {
        return e
    }

    if (!e.response) {
        if (e.data && e.data.message) {
            return e.data.message
        }

        return defaultMessage
    }

    if (!e.response.data) {
        if (e.response.message) {
            return e.response.data.message
        }

        return defaultMessage
    }

    if (!e.response.data.errors) {
        if (typeof e.response.data.message === 'string') {
            return e.response.data.message
        }

        return defaultMessage
    }

    if (Object.keys(e.response.data.errors).length < 1) {
        return defaultMessage
    }

    return Object.values(e.response.data.errors).join(', ')
}

const FetchAllOfPaginatedResource = async (url, options = {}) => {
    let resources = [];
    url = Storm.apiUrl + '/' + url;

    let response = await axios.get(url, options);

    resources = resources.concat(response.data.data);

    while (response.data.links.next) {
        response = await axios.get(response.data.links.next, options);
        resources = resources.concat(response.data.data);
    }

    return resources;
}

export {
    ExtractApiError,
    FetchAllOfPaginatedResource,
}

